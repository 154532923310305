export class CoverURL {
	value: string
	constructor(url: string | string[] | undefined) {
		if (!url) {
			this.value = ''
			return
		}
		const BASE_URL =
			'https://tagmeimages.azureedge.net/?q=90&output=webp&url=https://tagmepub.azureedge.net'
		const PUB_IMG = BASE_URL + '/pubimg'
		this.value = `${PUB_IMG}/${url}`
	}
}
